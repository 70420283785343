import React, { useContext, useEffect, useState } from "react";
import { GalleryItem } from "helpers/types";
import { Link } from "react-router-dom";
import GalleryImage from "pages/cases/view/GalleryImage";
import { getImageFilename, getTimeIn12h } from "helpers/utils";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { Spinner, Button } from "react-bootstrap";
import CameraMediaApi from "api/camera-media-api";
import ImagePopupModal from "pages/cases/view/ImagePopupModal";
import CaseApi from "api/case-api";
import { CaseImagesContext } from "pages/cases/view/case-images/CaseImages";




type IProps = {
    name: string;
    images: any[];
}



const mediaApi = new CameraMediaApi();
const caseApi = new CaseApi();

const ImageGallery = (props: IProps) => {
    const {
        selectImageIds,
        setSelectImageIds,
        lockedImageIds,
        setLockedImageIds,
        caseInfo,
        locationInfo,
        setLocationInfo,
        captureDate,
        contextMenuContainer,
        onShowToastMessage: showToastMessage,
        imageRequestHD
    } = useContext(CaseImagesContext);
    const [images, setImages] = useState<GalleryItem[]>([]);
    const [isSavingRoi, setIsSavingRoi] = useState(false);
    const [showRoiModal, setShowRoiModal] = useState(false);
    const [currentViewImageIndex, setCurrentViewImageIndex] = useState(0);
    const [roiPopupImage, setRoiPopupImage] = useState<any>();
    const [isPreparingDownload, setIsPreparingDownload] = useState(false);

    useEffect(() => {
        setImages([...props.images])
    }, [props.images]);

    const getImageSrc = (galleryIndex: number) => images[galleryIndex]?.image_src;

    const onImageDownloaded = (idx: number, imageData: string, downloadURL: string) => {
        const image = images[idx]
        if (image === undefined) {
            return;
        }
        if (image.hd_file_path !== null) {
            image.filename = image.filename.replace('sd', 'hd')
        }

        image.download_url = downloadURL
        image.image_src = imageData
        setImages((prevImages) => {
            const updatedImages = [...prevImages]
            updatedImages[idx] = image
            return updatedImages
        })
    }

    const isSelectImage = (imageId: number) => {
        return selectImageIds.includes(imageId);
    }

    const onImageSelected = (imageId: number) => {
        setSelectImageIds((prevIds: number[]) => {
            const updatedIds = [...prevIds]
            if (!isSelectImage(imageId)) {
                updatedIds.push(imageId);
            } else {
                const indx = updatedIds.findIndex(id => id === imageId);
                updatedIds.splice(indx, 1);
            }
            return [...updatedIds]
        })
    }

    const requestHD = async (index: number, imageId: number) => {
        imageRequestHD(index, imageId, images, setImages, mediaApi);
    };

    const toggleMediaLockStatus = async (imageId: number) => {
        if ( lockedImageIds.includes(imageId) ) {
            try {
                let api_resp = await mediaApi.markMediaAsUnlocked(imageId);
                

                if (api_resp.exit_code < 0){
                    showToastMessage && showToastMessage('There was a problem unlocking image');
                    return;
                }

                setLockedImageIds((prevIds: number[]) => {
                    const newIds = prevIds.filter((id) => id !== imageId);
                    return newIds;
                });
            } catch (e) {
                console.error(e)
            } 
        } else {
            try {
                let api_resp = await  mediaApi.markMediaAsLocked(imageId);

                if (api_resp.exit_code < 0){
                    showToastMessage && showToastMessage('There was a problem locking image');
                    return;
                }

                setLockedImageIds((prevIds: number[]) => {
                    const newIds = [...prevIds, imageId];
                    return newIds;
                });
            } catch (e) {
                console.error(e)
            } 
        }
    }

    const isImageMarkedAsLocked = (imageId: number) => {
        let isLocked = false;
        if (lockedImageIds && lockedImageIds.includes(imageId)){
            isLocked = true;
        }
        return isLocked;
    }

    async function onSaveRegionsOfInterest(regions: any) {
        try {
            setIsSavingRoi(true);
            const location = await caseApi.updateLocation(caseInfo.id, locationInfo.id, {
                regions_of_interest: regions
            });

            setLocationInfo({...location})
        } catch (e) {
            console.error(e)
        } finally {
            setIsSavingRoi(false)
        }
    }

    const getImageInfo = (index: number) => {
        return images[index];
    }

    const onViewNextImage = () => {
        if (currentViewImageIndex === images.length - 1) {
            return;
        }
        setCurrentViewImageIndex(currentViewImageIndex + 1)
    }

    const onViewPreviousImage = () => {
        if (currentViewImageIndex <= 0) {
            return;
        }
        setCurrentViewImageIndex(currentViewImageIndex - 1)
    }

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent): void => {
            if (event.keyCode === 39) {
                onViewNextImage();
            } else if (event.keyCode === 37) {
                onViewPreviousImage();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [currentViewImageIndex, images.length]);


    const onHideRoiModal = () => {
        setRoiPopupImage(null);
        setShowRoiModal(false);
    }

    const openLightBox = (index: number, imageSrc: string) => {
        setCurrentViewImageIndex(index);
        onShowRoiModal(imageSrc)
    }

    const onShowRoiModal = (imageSrc?: string) => {
        setRoiPopupImage(imageSrc)
        setShowRoiModal(true);
    };

    const createDownloadLink = (url: string) => {
        const a = document.createElement('a');
        a.href = url;
	let filename:string = `${captureDate}-${props.name.toUpperCase()}-${caseInfo.name}-${locationInfo?.name}.zip`;
	// Avoid whitespace and colons in filenames
        a.download = filename.replace(/[:\s]+/g,"_");
        return a
    }

    const downloadAlbum = async () => {
        try {
            setIsPreparingDownload(true)
            const mediaIds = images.map(image => image.id)
            if (images.length === 0) {
                showToastMessage && showToastMessage("No images to download");
                return;
            }
            const filterHd = props.name == 'HD'
	    console.log("downloadAlbum() Call mediaApi.downloadMedia()");
            const zipBlob = await mediaApi.downloadMedia(caseInfo.id, mediaIds, filterHd);
	    console.log("zipBlob: ");
	    console.log(zipBlob);
            const blobUrl = URL.createObjectURL(zipBlob);
	    console.log("blobURL: ");
	    console.log(blobUrl);
            const downloadLink = createDownloadLink(blobUrl);
	    console.log("Download link: ");
	    console.log(downloadLink);
	    console.log("Download link href: " + downloadLink.href);
	    console.log("Download link download: " + downloadLink.download);
            downloadLink.click();
            URL.revokeObjectURL(blobUrl)

        } catch (e) {
            console.log("Exception when downloading album: " + e);
            showToastMessage && showToastMessage("Error preparing download", "danger");
        } finally {
            setIsPreparingDownload(false);
        }
    };

    return (
        <React.Fragment>
            {
                contextMenuContainer?.current &&
                createPortal(
                    <React.Fragment>
                        {
                            isPreparingDownload ? <Spinner size="sm" className="text-warning"/>
                                : (
                                    images.length > 0 &&
                                    <Link to="#"
                                          className="filter-menu-item position-absolute"
                                          onClick={downloadAlbum}>
                                                <span
                                                    className="material-symbols-outlined album-download-icon ">download</span>
                                    </Link>
                                )
                        }
                    </React.Fragment>,
                    contextMenuContainer.current,
                    "album-download"
                )
            }

            {/* Image Gallery */}
            <div className="folder-list-container ">
                {
                    images.map((image, idx) => {
                        return (
                            <React.Fragment key={image.id}>
                                <div className="gallery-image-container ribbon-box">
                                    {
                                        isSelectImage(image.id) &&
                                        <div className="select-ribbon select-ribbon-primary">
                                            <span>Select</span>
                                        </div>
                                    }
                                    <Link
                                        to="#"
                                        className="image-popup"
                                        title={image.filename}>
                                        <GalleryImage
                                            onImageDownloaded={
                                                (downloadURL: string,
                                                 dataUrl: string
                                                ) => onImageDownloaded(idx, dataUrl, downloadURL)
                                            }
                                            src={getImageSrc(idx)}
                                            onClick={(imageSrc: string) => openLightBox(idx, imageSrc)}
                                            media={image}
                                            className="img-fluid img"/>
                                    </Link>
                                    <div className="gallery-image-info">
                                        <h4 className="font-16 mt-0"># {image.sequence}</h4>
                                        <Link to="#">
                                            <span
                                                className="text-muted ms-1">{getTimeIn12h(image.captured_at)}</span>
                                        </Link>
                                        <div className="gallery-img-btns">
                                            <Link to="#">
                                                {image.hd_request_status === "PENDING" ? (
                                                        <span className="hd-pending text-warning">
                                                            <span className="material-symbols-outlined">hd</span>
                                                        </span>
                                                    ) :
                                                    (<span
                                                        onClick={() => requestHD(idx, image.id)}
                                                        className={classNames("material-symbols-outlined",
                                                            {
                                                                "text-muted": image.hd_file_path === null,
                                                                "text-success": image.hd_file_path !== null
                                                            })}>hd
                                                </span>)}
                                            </Link>
                                            <Link to={image["download_url"]}
                                                  target="_target"
                                                  download={getImageFilename(image)}>
                                                <span className={classNames("material-symbols-outlined",
                                                    {
                                                        "text-primary": image["download_url"] !== undefined,
                                                        "text-muted": image["download_url"] === undefined
                                                    })}>download</span>
                                            </Link>
                                            <Link to="#">
                                                <span
                                                    onClick={() => onImageSelected(image.id)}
                                                    className={classNames("material-symbols-outlined",
                                                        {
                                                            "text-muted": !isSelectImage(image.id),
                                                            "text-success": isSelectImage(image.id)
                                                        })}>{isSelectImage(image.id) ? "select_check_box" : "check_box_outline_blank"}
                                                </span>
                                            </Link>
                                            <Link to="#">
                                                <span
                                                    onClick={() => toggleMediaLockStatus(image.id)}
                                                    className={classNames("material-symbols-outlined", "lock_unlock_media_icon",
                                                        {
                                                            "text-muted": !isImageMarkedAsLocked(image.id),
                                                            "text-success": isImageMarkedAsLocked(image.id),
                                                        })}>{lockedImageIds.includes(image.id) ? "lock" : "lock_open"}
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })
                }
            </div>

            {/* Image popup */}
            {roiPopupImage &&
                <ImagePopupModal
                    show={showRoiModal}
                    isSaving={isSavingRoi}
                    onNext={onViewNextImage}
                    onPrev={onViewPreviousImage}
                    onHdRequested={requestHD}
                    onSaveRegionsOfInterest={onSaveRegionsOfInterest}
                    regions={locationInfo.regions_of_interest || []}
                    imageIndex={currentViewImageIndex}
                    imageSrc={getImageSrc(currentViewImageIndex)}
                    imageInfo={getImageInfo(currentViewImageIndex)}
                    isSelect={isSelectImage(getImageInfo(currentViewImageIndex).id)}
                    onImageSelected={onImageSelected}
                    toggleMediaLockStatus={toggleMediaLockStatus}
                    isImageMarkedAsLocked={isImageMarkedAsLocked}
                    onHide={onHideRoiModal}/>
            }
        </React.Fragment>
    );
}

export default React.memo(ImageGallery);