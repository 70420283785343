import { BackendApi } from "./backend-api";
import {
    Case,
    CaseLocation,
    GetCasesResponse,
    GetImagesResponse,
    GetLocationsResponse
} from "../helpers/interfaces";


export default class CaseApi {
    private backendApi: BackendApi;

    constructor() {
        this.backendApi = BackendApi.Instance;
    }

    async listCases(args?: any): Promise<GetCasesResponse> {
        const {offset, limit} = args;
        return this.backendApi.getApiResponse({
            endPoint: `/cases`,
            queryStringParams: {
                offset: offset,
                limit: limit
            }
        });
    }

    async fetchCase(caseId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}`
        })
    }

    async addCase(_case: Case) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases`,
            method: "POST",
            data: _case
        })
    }

    async updateCase(_case: Case) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${_case.id}`,
            method: "PUT",
            data: _case
        })
    }

    async listUnassignedUsers(caseId: number, args?: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/users/unassigned`,
            queryStringParams: args
        })
    }

    async listCaseUsers(caseId: number, args?: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/users`,
            queryStringParams: args
        })
    }

    async addCaseUsers(caseId: number, userIds: number[]) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/users/add`,
            method: "PUT",
            data: {
                userIds: userIds
            }
        })
    }

    async removeCaseUsers(caseId: number, userIds: number[]) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/users/remove`,
            method: "PUT",
            data: {
                userIds: userIds
            }
        })
    }

    async deleteCase(caseId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}`,
            method: "DELETE"
        })
    }

    async listLocations(args: any): Promise<GetLocationsResponse> {
        const {offset = 0, limit = 1000, caseId} = args;
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations`,
            queryStringParams: {
                offset: offset,
                limit: limit
            }
        });
    }

    async addLocation(caseId: number, location: CaseLocation) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations`,
            method: "POST",
            data: location
        })
    }

    async deleteLocation(caseId: number, locationId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations/${locationId}`,
            method: "DELETE"
        })
    }

    async updateLocation(caseId: number, locationId: number,  data: any) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations/${locationId}`,
            method: "PUT",
            data: data
        })
    }

    async listLocationCaptureDates(caseId: number, locationId: number) {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations/${locationId}/dates`
        })
    }

    async getLocation(caseId: number, locationId: number): Promise<CaseLocation> {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations/${locationId}`
        })
    }

    async getLocationImages(caseId: number, locationId: number, args: any): Promise<GetImagesResponse> {
        return this.backendApi.getApiResponse({
            endPoint: `/cases/${caseId}/locations/${locationId}/images`,
            queryStringParams: args
        })
    }
}