import React, { useCallback, useEffect, useState } from "react";
import { Pagination, FormControl, FormLabel, FormSelect, Card } from "react-bootstrap";
import classNames from "classnames";

type IProps = {
    pageSize: number;
    totalPages: number;
    currentPage: number;
    onChangePage: (newPage: number) => void;
    onGoToImage?: (sequence: string) => void;
    onSetPageSize: (size: number) => void;
    className?: string | undefined;
}

const sizePerPageList = [
    {text: '48', value: 48},
    {text: '96', value: 96},
    {text: '144', value: 144},
    {text: '192', value: 192},
    {text: '240', value: 240}
];

const ListPagination = ({ currentPage, ...props}: IProps) => {
    const filterPages = useCallback(
        (visiblePages: any) => {
            return visiblePages.filter((page: any) => page <= props.totalPages);
        }, [props.totalPages]);

    const getVisiblePages = useCallback(
        (currentPage: number) => {
            if (props.totalPages < 10) {
                return filterPages([1, 2, 3, 4, 5, 6, 7, 8, 9]);
            } else {
                if (currentPage % 5 >= 0 && currentPage > 4 && currentPage + 2 < props.totalPages) {
                    return [1, currentPage - 1, currentPage, currentPage + 1, props.totalPages];
                } else if (currentPage! % 5 >= 0 && currentPage! > 4 && currentPage! + 2 >= props.totalPages) {
                    return [1, props.totalPages - 3, props.totalPages - 2, props.totalPages - 1, props.totalPages];
                } else {
                    return [1, 2, 3, 4, 5, props.totalPages];
                }
            }
        }, [filterPages, props.totalPages]);

    const [visiblePages, setVisiblePages] = useState<number[]>(getVisiblePages(1));

    useEffect(() => {
        const visiblePages = getVisiblePages(currentPage);
        setVisiblePages(filterPages(visiblePages));
    }, [currentPage, getVisiblePages, filterPages]);

    return (
        <React.Fragment>
            <Card className={props.className}>
                <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            {sizePerPageList.length > 0 && (
                                <div className="d-inline-block me-3">
                                    <label className="me-1">Display :</label>
                                    <FormSelect
                                        value={props.pageSize}
                                        onChange={(e: any) => {
                                            props.onSetPageSize(Number(e.target.value));
                                        }}
                                        className="d-inline-block w-auto">
                                        {(sizePerPageList || []).map((pageSize, index) => {
                                            return (
                                                <option key={index} value={pageSize.value}>
                                                    {pageSize.text}
                                                </option>
                                            );
                                        })}
                                    </FormSelect>
                                </div>
                            )}
                            <span>Page{" "}
                                <strong> {currentPage} of {props.totalPages}</strong>{" "}
                            </span>
                            <span className="ms-3 d-inline-block align-items-center">
                              <FormLabel className="me-2">Go to page : </FormLabel>
                              <FormControl
                                  className="w-25 d-inline-block"
                                  type="number"
                                  value={currentPage}
                                  min="1"
                                  onChange={(e: any) => {
                                      const page = !e.target.value || Number(e.target.value) === 0 ? 1 : Number(e.target.value);
                                      props.onChangePage(page);
                                  }}
                              />
                            </span>
                            <div className="d-inline-block align-items-center">
                              <FormLabel className="me-2">Go to Image : </FormLabel>
                                <FormControl
                                    className="w-25 d-inline-block"
                                    type="text"
                                    onChange={(e: any) => {
                                        const val = e.target.value;
                                        props.onGoToImage && props.onGoToImage(val);
                                    }}
                                />
                            </div>
                        </div>
                        <Pagination className="justify-content-end m-0 flex-3">
                            <Pagination.Prev
                                className={classNames({disabled: currentPage === 1})}
                                onClick={() => {
                                    if (currentPage === 1) return;
                                    props.onChangePage(currentPage - 1)
                                }}
                            />
                            {
                                visiblePages.map((page, idx, arr) => {
                                    return arr[idx - 1] + 1 < page ? (
                                        <React.Fragment key={idx}>
                                            <Pagination.Item className="disabled">...
                                            </Pagination.Item>
                                            <Pagination.Item
                                                active={page === currentPage}
                                                onClick={() => props.onChangePage(page)}>{page}
                                            </Pagination.Item>
                                        </React.Fragment>
                                    ) : (
                                        <Pagination.Item
                                            key={idx}
                                            active={page === currentPage}
                                            onClick={() => props.onChangePage(page)}>{page}
                                        </Pagination.Item>
                                    )
                                })
                            }
                            <Pagination.Next
                                className={classNames({disabled: currentPage === props.totalPages})}
                                onClick={() => {
                                    if (currentPage === props.totalPages)
                                        return;
                                    props.onChangePage(currentPage + 1)
                                }}/>
                        </Pagination>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}

export default React.memo(ListPagination);