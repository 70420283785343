import React, { useEffect, useState } from 'react';
import CameraMediaApi from "../../../api/camera-media-api";
import { Placeholder } from "react-bootstrap";
import classNames from "classnames";
import { CameraMedia } from "../../../helpers/interfaces";

const cameraMediaApi = new CameraMediaApi();

type ImageProps = {
    className?: string;
    src: string;
    onClick?: (imageSrc: string) => void;
    media?: CameraMedia;
    onImageDownloaded?: (url: string, result: any) => void;
    [key: string]: any;
}
const GalleryImage = (props: ImageProps) => {
    const [imageSrc, setImageSrc] = useState<any>()
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getImage() {
            try {
                if (!props.media) {
                    return;
                }
                setIsLoading(true);
                let filename = props.media.filename;
                const getHd = props.media.hd_file_path !== null;
                if (getHd) {
                    filename = filename.replace('sd', 'hd');
                }
                const blob = await cameraMediaApi.getImage(props.media.camera_id, filename);
                try {
                    const downloadURL = URL.createObjectURL(blob);
                    // Create a new FileReader instance
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        setImageSrc(reader.result)
                        if (props.onImageDownloaded) {
                            props.onImageDownloaded(downloadURL, reader.result)
                        }
                    });
                    reader.readAsDataURL(blob);
                } catch (err) {
                    console.log('error loading image', err);
                    setImageSrc(null)
                } finally {
                    setIsLoading(false);
                }
            } catch (e) {
                console.log(e)
            }
        }

        if (props.src) {
            setImageSrc(props.src)
        } else {
            getImage()
        }
    }, [props.src]);


    const onImageClicked = () => {
        if (props.onClick) {
            props.onClick(imageSrc);
        }
    }

    return (
        <React.Fragment>
            {isLoading ?
                <Placeholder as="p" animation="glow">
                    <Placeholder bg="dark" className={classNames(props.className, "img-fluid")}
                                 style={{minHeight: "180px"}} xs={12}/>
                </Placeholder>
                :
                <img
                    onClick={onImageClicked}
                    src={imageSrc} alt=""
                    className={classNames(props.className, "img-fluid")}/>
            }
        </React.Fragment>
    );
}

export default GalleryImage;
